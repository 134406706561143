.pdfModal-Container {
  /* background:#afafaf!important; */
  font-size: 24px;
  font-family: "FoundersGrotesk-Light";
}

.pdfViewer-Modal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
.pdf-viewer-container {
  width: 100%;
  height: 100%;
}
.pdf-viewer {
  height: 65vh;
  overflow: scroll;
}
@media screen and (max-width: 1024px) {
  .pdfViewer-Modal {
    height: 100% !important;
  }
}

@media (max-width: 576px) {
  .pdfViewer-Modal {
    height: 90% !important;
    margin-top: 2vw !important;
  }
  .pdf-viewer-container {
    height: 50% !important;
  }
  .pdf-viewer {
    height: 50vh;
    overflow: scroll;
  }
}

.section1 {
  display: flex;
  flex-direction: column;
  font-family: "Open Sans", sans-serif;
  font-weight: 200;
  padding-bottom: 20px;
}

.section1-input {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  margin-top: 30px;
}
.pdf-proceed {
  width: auto;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ee8155;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  color: black;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  text-decoration: none;
  margin-left: 20px;
}
.pdfModalBackdrop {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
}
.close-x {
  position: relative;
  left: 48%;
  font-weight: 800;
  cursor: pointer;
}
.close-x-2 {
  position: relative;
  font-weight: 800;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
}
.pdf-header {
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding: 15px;
  font-family: "FoundersGrotesk-Light";
}
.pdfheader-span {
  flex: 0.8;
  font-family: "FoundersGrotesk-Light";
  font-weight: 200;
}
.download-button {
  background-color: #ee8155;
  border: none;
  border-radius: 10px;
  color: #ffffff;
  cursor: pointer;
  padding: 3px;
  flex: 0.2;
  text-decoration: none;
  height: 40px;
}
.download-link {
  text-decoration: none;
  color: black;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.pdfInput {
  border: none;
  border-radius: 5px;
  color: black;
  height: 100%;
  padding-left: 10px;
}
.bold {
  font-weight: 600;
}
.example {
  font-size: 1.5rem;
  margin-top: 10px;
}
.iconWhite path {
  stroke: white;
}
.errMsg {
  color: #ffff00;
  font-size: 20px;
  margin-top: 10px;
}
.noMargin {
  margin-left: -20px;
}
.pdfRow {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pdfInputIcon {
  display: grid;
  place-items: center;
  position: absolute;
  right: 0;
  margin-right: 10px;
  cursor: pointer;
  color: black;
}

@media (max-width: 1024px) {
  .pdfModalBackdrop {
    background-color: rgba(0, 0, 0, 0.5) !important;
    position: fixed;
    /* top: 10% !important;
    left: 50%;
    transform: translateX(-50%); */
    z-index: 100;
    width: 100% !important;
    height: 100% !important;
  }
  /* If .pdfModalBackdrop is active, then change .modal-body  style */
  .pdfModalBackdrop .modal-body {
    width: 90%;
    height: 60%;
    border-radius: 6px;
    padding: 0;
    margin: 0;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid white;
    overflow: hidden;
    overflow-y: scroll;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  }
  .pdfModalBackdrop .modal-content {
    background-color: rgba(0, 0, 0, 0.25);
  }
  .pdfModalBackdrop .pdfModal-Container {
    max-width: 650px;
    margin: 0 auto;
    padding: 20px;
    height: 100%;
    font-size: 18px;
    /* background-color: red; */
  }

  .section1-input {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: auto;
  }
  .section1-input {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    margin-top: 30px;
  }
  .proceedButton {
    margin-top: 20px;
    margin-left: 0px;
  }
  .close-x {
    position: relative;
    left: 45%;
  }
  .close-x-2 {
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
  }
  .errMsg {
    color: #ffff00;
    font-size: 16px;
    margin-top: 25px;
  }

  .pdf-navigation {
    position: relative;
  }

  .pdf-navigation-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #f5f5f5;
    padding: 8px;
  }
}

.spinner {
  border: none;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
