.history-table {
    border: 1px solid lightgray;
    border-radius: 10px;
    overflow: hidden;
    border-collapse: collapse;
    width: 100%;
    margin: 0 auto;
    /* font-family: "FoundersGrotesk-Light"; */
}

/* material table header styling */
.history-table table:first-child th {
    font-size: 1.5rem !important;
    font-weight: 600 !important;
}

.history-table td:nth-child(2),
.history-table th:nth-child(2) {
  min-width: 180px !important;
  width: 100% !important;
}

.history-table td:nth-child(1),
.history-table th:nth-child(1) {
  min-width: 40vw !important;
}

@media (max-width: 1024px) {
    .history-table td:nth-child(3),
    .history-table th:nth-child(3) {
      min-width: 180px !important;
      width: 100% !important;
    }
    
    .history-table td:nth-child(2),
    .history-table th:nth-child(2) {
      width: auto !important;
      min-width: auto !important;
    }

    .history-table td:nth-child(1),
.history-table th:nth-child(1) {
  min-width: auto !important;
}
    
}

.history-table table:first-child tr:nth-child(odd) {
	background-color: white;
}

.history-table table:first-child tr:nth-child(even) {
	background-color: #eee;
}

.body_history .btn.focus, .body_history .btn:focus, .body_history .btn:hover {
    color: white;
    text-decoration: none;
}

/* .body_history th {
    font-size:16px;
} */

.body_history .material-icons, .body_history .MuiSvgIcon-root {
    font-size: 2.5rem;
}
  
.body_history .modal-dialog {
    margin: auto;
}

.body_history .modal-dialog .MuiPaper-root {
    padding-top: 20px;
    padding-bottom: 20px;
}

.body_history .modal-dialog .MuiPaper-root h6 {
    font-size: 2.2rem;
}

.body_history .modal-dialog .MuiToolbar-gutters {
    padding-left: 16px;
}

.share-modal-wrap {
    color: black;
    padding: 0 20px 20px 20px;
}

.share-modal-wrap h2 {
    margin:0;
}

.share-modal-wrap input[type="checkbox"] {
    visibility: visible;
}

.share-modal-wrap .mr-10 {
    margin-right: 10px;
}

.share-data {
    width: 70%;
}

.share-color-results {
    justify-content: center;
    align-items: center;
    color: white;
    height: 100px;
    width: 100px;
}

.share_inputs {
    border-radius: 3rem;
    height: 40px;
    border: 1px solid #EC86C5;
    width: 100%;
    color: black;
    font-size: 20px;
    padding-left: 2rem;
    margin-right:20px;
}
.share-checkbox-wrap p {
    margin: 0;
}

.mt-20 {
    margin-top: 20px;
}

.bg-green {
    background-color: #39FF14;
}

.bg-red {
    background-color: red;
}

.bg-gray {
    background-color: gray;
}

.share-modal-wrap .btn.focus, .share-modal-wrap .btn:hover {
    color: white;
    outline: none;
}

.flex-desktop {
    display:flex;
}

.share-modal-wrap .modal-header, .recipient-header-wrap .modal-header {
    font-family: "FoundersGrotesk-Light";
    background: #fff;
    text-align: left;
    padding-left: 0;
}

.share-modal-wrap .modal-title, .recipient-header-wrap .modal-title {
    text-align: left;
    display: inline-block;
    padding:0;
}

.recipient-header-wrap {
    padding-left: 10px;
    padding-right: 10px;
    height: 520px;
}

.recipient-header-wrap .modal-header {
    padding-bottom: 0;
}

.recipient-header-wrap h2 {
    color: black;
    padding-left: 16px;
    margin: 0;
}

.body_history .recipient-header-wrap .MuiPaper-root {
    padding-top: 0;
}

.body_history .MuiPaper-elevation2 {
    box-shadow: none;
}

.text-green {
    color: #39FF14;
}

.text-red {
    color: red;
}

.text-gray {
    color: gray;
}

.MuiAlert-message {
    padding: 8px 0;
    font-size: 18px;
}

.body_history .site-header .mobile-show {
    z-index: 223;
}

.body_history .MuiSnackbar-root .MuiAlert-action {
    align-items: flex-start;
}

.share-color-results p {
    font-weight: 900;
}

.share-modal-wrap .flex-desktop  {
    align-items: center;
}

.share-modal-wrap .flex-desktop p  {
    cursor: pointer;
    margin: 0;
}

.share-modal-wrap button {
    justify-content: center;
}

.mr-38 {
    margin-right: 38px;
}

.align-items-center {
    align-items: center;
}

.share-img-upload img {
    width: 100px;
    height: 100px;
}

.share-img-upload img {
    border-radius: 50%;
    border: 1px solid black;
}

.back-arrow {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.to-share-page {
    background: #1F7F61;
    border-radius: 4;
    text-align: center;
    width: 100%;
    height: 60px;
    border: none;
    font-family: FoundersGrotesk-Light;
    font-size: 30px;
    font-weight: 900;
    color: #FFFFFF;
    letter-spacing: 0;
    text-align: center;
    outline: transparent;
    display: flex;
    align-items: center;
}

.modal-header .close {
    position: absolute;
    right: 20px;
}

.upload__image-wrapper a {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.upload__image-wrapper a:hover {
    cursor: pointer;
    text-decoration: none;
}

.change-image-text {
    font-size: 15px;
    color: black;
}

.color-red {
    color: red;
}

.share-results-btn {
    background: #1F7F61;
    border-radius: 100rem;
    text-align: center;
    width: 19rem;
    height: 40px;
    border: none;
    font-family: FoundersGrotesk-Light;
    font-size: 22px;
    color: #FFFFFF;
    letter-spacing: 0;
    text-align: center;
    outline: transparent;
    display: flex;
    align-items: center;
}

.add-recipient {
    background-color: #EC86C5;
}

.MuiTableCell-paddingNone button:nth-child(3),.MuiTableCell-paddingNone button[title="Share"]:nth-child(3):hover {
    transform: scaleX(-1);
}

@media only screen and (min-width: 769px) {
    .body_history .modal {
        display:flex !important;
    }
}

@media only screen and (max-width: 768px) {
    .share-modal-wrap {
        text-align: center;
    }
    .share-data {
        width: 100%;
    }
    .share-color {
        align-items: center;
    }
    .flex-desktop {
        flex-direction: column;
    }
    .share_inputs {
        margin-bottom: 20px;
        margin-right: 0;
    }
    .share-img-upload {
        width: 35%;
    }
    .share-details {
        text-align:left;
        width: 65%;
    }
    .share-details p {
        font-size: 24px;
    }
    .share-img-upload img {
        width: 100%;
        height: 100%;
    }
    .share-color {
        margin-top: 20px
    }
    .share-color-results {
        margin:0;
        width: 100%;
        height: 200px;
    }
    .body_history.modal-open .modal-content {
        min-height: 100vh;
    }
}

@media only screen and (max-width: 413px) {
    .share-details p {
        font-size: 18px;
    }
}
