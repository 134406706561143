.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.card {
  display: flex;
  width: 80%;
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.left-section {
  flex: 3.5;
  display: flex;
  flex-direction: column;
}

.sub-id {
  font-weight: bold;
  font-family: "Founders Grotesk";
  color: #d83e2c;
  font-weight: 400;
  font-size: 1.5rem;
}

.kit-name {
  font-weight: 700;
  font-size: 2rem;
  font-family: "GTSuperDSTrial";
  color: #1f1e21;
  margin-top: 1rem;
}

.right-section {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.section-1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}
.sub-detail {
  font-family: "Founders Grotesk Medium";
  font-weight: 500;
  font-size: 1.5rem;
  color: #1f1e21;
}
.sub-type {
  font-family: "Founders Grotesk";
  color: #4e4b53;
  font-weight: 400;
  font-size: 1.5rem;
  margin-top: 0.5rem;
}
.sub-date {
  font-family: "Founders Grotesk";
  color: #4e4b53;
  font-weight: 400;
  font-size: 1.5rem;
  margin-top: 0.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.section-2 {
  display: flex;
  flex: 1;
  min-height: 100%;
  justify-content: center;
  align-items: center;
}

.modify-button {
  background-color: transparent;
  color: #007f61;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Founders Grotesk Medium";
  font-weight: 500;
  font-size: 1.8rem;
}

@media (max-width: 768px) {
  .card {
    flex-direction: column;
    align-items: center;
  }

  .left-section,
  .right-section {
    flex: 1;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .sub-id {
    font-size: 1.2rem;
  }

  .kit-name {
    font-size: 1.5rem;
  }

  .right-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .sub-detail,
  .sub-type,
  .sub-date {
    font-size: 1.2rem;
  }

  .modify-button {
    font-size: 1.4rem;
  }
}

.modify-button:hover {
}

.updateSubModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.updateSubModal-content {
  width: 40vw;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  border-radius: 1rem;
}
.updateSubModal-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.updateSubModal-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.updateSubModal-title {
  font-weight: 500;
  font-size: 2rem;
  font-family: "Founders Grotesk Medium";
}
.updateSubModal-navPanel {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.skipNextShipment-cont {
  margin-top: 2rem;
}
.text-input {
  width: 100%;
  /* height: 3rem; */
  border: 1px solid #d8d8d8;
  padding: 0.5rem;
  font-family: "Founders Grotesk";
  font-size: 2rem;
  color: #1f1e21;
  margin: 1rem 0;
}
.textInput-cont {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 1rem 0;
  margin-bottom: 3rem;
}
.text-input-label {
  font-weight: 500 !important;
  font-size: 1.8rem !important;
  font-family: "Founders Grotesk Medium" !important;
  color: #1f1e21 !important;
}
.skipNextShipment-btt-cont {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 4rem;
}
.updateSubModal-cancel-bttn {
  background-color: transparent;
  color: #007f61;
  padding: 8px 26px;
  border: 1px solid #007f61;
  border-radius: 24px;
  cursor: pointer;
  font-family: "Founders Grotesk Medium";
  font-weight: 500;
  font-size: 1.8rem;
  margin-right: 1rem;
}
.updateSubModal-send-bttn {
  background-color: #007f61;
  color: #fff;
  padding: 8px 26px;
  border: 1px solid #007f61;
  border-radius: 24px;
  cursor: pointer;
  font-family: "Founders Grotesk Medium";
  font-weight: 500;
  font-size: 1.8rem;
  margin-right: 1rem;
}

@media (max-width: 1024px) {
  .updateSubModal-content {
    width: 70vw;
  }
}

@media (max-width: 768px) {
  .updateSubModal-content {
    width: 90vw;
  }

  .updateSubModal-title {
    font-size: 1.8rem;
  }

  .text-input {
    font-size: 1.6rem;
  }

  .updateSubModal-cancel-bttn,
  .updateSubModal-send-bttn {
    font-size: 1.6rem;
    padding: 8px 20px;
    margin-right: 0.5rem;
  }

  .skipNextShipment-btt-cont {
    margin-top: 2rem;
    justify-content: space-between;
  }
  .updateSubModal-navPanel {
    flex-direction: column;
  }
}

.updateSubModal-send-bttn.loading {
  position: relative;
  color: #007f61;
}

/* Style the loading spinner */
.updateSubModal-send-bttn.loading::after {
  content: "";
  width: 20px;
  height: 20px;
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;
  animation: spin 1s linear infinite;
}

/* Keyframe animation for the loading spinner */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.no-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10rem;
}

.no-subscription-title {
  font-family: "Founders Grotesk Medium";
  font-weight: 500;
  font-size: 3rem;
  color: #979797;
  margin-bottom: 1rem;
}
.helper-text {
  font-weight: 400 !important;
  font-size: 1.4rem !important;
  font-family: "Founders Grotesk Medium" !important;
  color: #1e1e1f !important;
}
.loading-container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  flex-direction: column;
  gap: 1rem;

}
.loading {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  margin: 20px auto;
}