/* form#signupFormPage1 {
    width: 500px;
}
form#signupFormPage2 {
    width: 500px;
}

form#signupFormPage3 {
    width: 500px;
} */

.red-outline {
    border: 2px solid red !important;
}

#symptoms, #medications, #allergies, #pre-existing {
    border-radius: 3rem;
    height: 100px;
    border: 2px solid black;
    background-color: transparent;
    width: 100%;
    color: black;
    font-size: 2.9rem;
    margin-bottom: 0px !important;
    resize: none;
}

.updateProfile_text {
    font-family: "FoundersGrotesk-Light";
	font-size: 3.5rem;
	letter-spacing: -0.53px;
    /* text-align: center; */
    display: block;
    margin-bottom: 20px;
}

#kitRegistrationForm .loading-button-spinner {
    color: white;
    margin-top: 40px;
}

.just-cent {
    justify-content: center;
}

.util_text {
    margin-top: 150px;
}

.countdown-content {
    margin-top: 120px;
}

.countdown-content .btn {
    margin-top: 30px;
}

.updateProfileDate {
    background-color: #FEFAF0;
    border-radius: 3rem;
    height: 5.9rem;
    border: 2px solid black;
    width: 100%;
    color: black;
    font-size: 2.9rem;
    margin-bottom: 15px;
    padding-left: 12px;
}

.updateProfileDate::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: .7; /* Firefox */
}

.body_portal .react-datepicker-wrapper {
    width: 100%;
}

.body_portal .countdown-content.countdown-flex {
    text-align: center;
}

.body_portal .countdown-content.countdown-flex button {
    margin-top: 50px;
}

.updateProfile-label {
    font-family: "FoundersGrotesk-Light" !important;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #000;
    display: block;
    margin-left: 10px;
    margin-bottom: 10px;
}

.updateProfile-errors {
    color: darkred;
    font-family: "FoundersGrotesk-Light";
    font-size: 2rem;
    letter-spacing: 0.31px;
    text-align: center;
    margin-bottom: 0rem;
}

#text{
    border-radius: 3rem;
	height: 5.9rem;
	border: 2px solid black;
	background-color: transparent;
	width: 100%;
	color: black;
	font-size: 2.9rem;
	margin-bottom: 15px;
}

#dob{
    background-color: #FEFAF0;
    border-radius: 3rem;
    height: 5.9rem;
    border: 2px solid black;
    width: 100%;
    color: black;
    font-size: 2.9rem;
    padding-left: 12px;
}

select#pronoun {
    border-radius: 3rem;
    height: 5.9rem;
    border: 2px solid black;
    background-color: transparent;
    width: 100%;
    color: black;
    font-size: 2.9rem;
    margin-bottom: 15px;
    background: url(down-arrow.png) no-repeat right 5% bottom 45%; 
    -webkit-appearance: none;
    text-align-last: left;
    background-size: 35px;
}

option {
    text-align: left;
}

label.profile-label {
    font-size: 15px;
    margin-left: 10px;
    font-weight: 400;
    margin-bottom: 10px;
}


#profileButtonRow{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 30px;
    justify-content: space-around;
    align-items: center;
}

.radioList {
    display: inline-block;
    position: relative;
    width: inherit;
    margin-left: 25px;
}