.circle-checkbox-group {
  display: flex;
  flex-direction: column;
}

.checkbox-label {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
  font-size: 2rem;
  font-family: "Founders Grotesk Medium";
  font-weight: 500;
}

.checkbox-label input {
  display: none;
}

.checkbox-custom {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #008000; /* Green color for border */
  margin-right: 8px;
  position: relative;
}

.checkbox-custom:before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #008000; /* Green color for checkmark */
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.3s ease-in-out;
}

.checkbox-label input:checked + .checkbox-custom:before {
  transform: translate(-50%, -50%) scale(1);
}

@media (max-width: 1024px) {
  .checkbox-label {
    font-size: 1.8rem;
  }
}
