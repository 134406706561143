.attachment-files {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #f5f5f5;
  color: #333;
  font-weight: bold;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  margin: 20px 0px;
}

.attachment-files-message-text {
  cursor: pointer;
  margin-left: 2rem;
}

.file-name-text {
  color: #b29ff1;
}

.send-message {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.cursor-pointer {
  cursor: pointer;
}

.custom-link {
  color: #007bff; /* Blue color */
  text-decoration: underline; /* Underline links */
  font-weight: bold; /* Bold text */
}

.custom-link:hover {
  color: #0056b3; /* Darker blue on hover */
  text-decoration: none; /* No underline on hover */
}
