/* General responsive adjustments */
@media (max-width: 768px) {
    .form-group.register-kit-form {
        padding: 0 20px;
    }

    .registerKitFieldsNew,
    .registerKitFieldsNewTextArea,
    .registerKitDate {
        font-size: 1.6rem;
        padding: 10px;
    }

    .registerkit_text {
        font-size: 2rem;
        text-align: center;
    }

    .registerKit-label {
        font-size: 1.6rem;
    }
    .checkbox-confirm {
        width: 100%;
    }

    #socialbutton-customizable1 {
        width: 100%;
        font-size: 1.8rem;
        height: 4.5rem;
    }

    .registerKit-login-buttons {
        margin-top: 20px;
    }

    .helpmodal-body {
        padding: 10px;
    }
}

/* Specific fix for the button visibility */
.row.registerKit-login-buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.row.registerKit-login-buttons:before {
    content: '';
    display: none !important;
}
.row.registerKit-login-buttons:after {
    content: '';
    display: none !important;
}

.red-outline {
    border: 2px solid red !important;
}

.registerkit_text {
    /* text-align: center; */
    display: block;
    margin-bottom: 20px;
}

#kitRegistrationForm .loading-button-spinner {
    color: white;
    margin-top: 40px;
}

.just-cent {
    justify-content: center;
}

.util_text {
    margin-top: 150px;
}

.countdown-content {
    margin-top: 120px;
}

.countdown-content .btn {
    margin-top: 30px;
}

.registerKitDate {
    background-color: #FEFAF0;
    border-radius: 3rem;
    height: 5.9rem;
    border: 2px solid black;
    width: 100%;
    color: black;
    font-size: 2.9rem;
    margin-bottom: 15px;
    padding-left: 12px;
}

.registerKitDate::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: .7; /* Firefox */
}

.body_portal .react-datepicker-wrapper {
    width: 100%;
}

.body_portal .countdown-content.countdown-flex {
    text-align: center;
}

.body_portal .countdown-content.countdown-flex button {
    margin-top: 50px;
}

.registerKit-label {
    font-family: "Founders Grotesk";
    width: 100%;
    text-align: left;
    font-size: 18px;
    text-align: left;
    display: block;
    margin-bottom: 5px;
}
.checkbox-confirm {
    width: 100%;
    max-width: 40vw;
}

.registerKit-errors {
    color: darkred;
    font-family: "FoundersGrotesk-Light";
    font-size: 2rem;
    letter-spacing: 0.31px;
    text-align: center;
    margin-bottom: 0rem;
}

.body_portal .react-datepicker-wrapper {
    width: 100%;
}

#socialbutton-customizable1 {
    background: #EE8155;
    border-radius: 100rem;
    text-align: center;
    padding: 0px 20px;
    border: none;
    font-family: FoundersGrotesk-Light;
    font-size: 22px;
    color: #000;
    letter-spacing: 0;
    text-align: center;
    outline: transparent;
    width: 25rem;
    height: 6rem;
    text-transform: uppercase;
}
