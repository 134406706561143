.red-outline {
    border: 2px solid red !important;
}
.radio-input{
    font-family: "FoundersGrotesk-Light";
    font-size: 0.75em;
    clear: both;
    font-weight: normal;
}
.radio-input span {
    margin-left: 10px;
}
.form-group{
    height: auto;
}
.terms-content{
    max-height: 40vh;
    overflow: auto;
    text-align: left;
}
.terms-content::-webkit-scrollbar,
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track {
    width: 8px;
    border: none;
    background: transparent;
}
.terms-content::-webkit-scrollbar-button,
::-webkit-scrollbar-track-piece,
::-webkit-scrollbar-corner,
::-webkit-resizer {
    display: none;
}
.terms-content::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: #ffffff;
}
.terms-content::-webkit-scrollbar-track {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAABCAYAAADjAO9DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAQSURBVHgBY2BAAv+hAFkMAIOdB/nb72DsAAAAAElFTkSuQmCC');
    background-repeat: repeat-y;
    background-size: contain;
}
.terms-content-blur {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 12vh;
    z-index: 10000;
    object-fit: cover;
    pointer-events: none;
}
.terms-modal-header{
    font-size: 1.2em;
}
p.formHint {
    font-size: 14px;
    font-style: italic;
    color: red;
    letter-spacing: 1px;
}