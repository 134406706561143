.user-profile-img {
    border-radius: 50%;
    border: 3px solid #7272727e;
}

.change-image-text p {
    color: #000;
    margin-top: 10px;
    font-family: "FoundersGrotesk-Light";
    font-size: 20px;
}
.infobox {
    padding: 0px 5px;
    border: 1px solid #aaa !important;
    color: #aaa !important;
    border-radius: 50% !important;
    transition: 0.4s;
    font-size: 1.2rem !important;
    background: transparent !important;
}
.infobox:hover {
    box-shadow: 0 0 5px 3px #ddd;
}

#button-tooltip{
    opacity: 1;
}
