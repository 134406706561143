.red-outline {
    border: 2px solid red;
}

.box-shadow-none {
    box-shadow: none;
}

button .loading-button-spinner {
    color: white;
    height: 20px !important;
    width: 20px !important;
    margin-left: 20px;
    position: absolute;
    top: 22px;
}

button.btn.socialbutton-customizable {
    position: relative;
}
.login-password{
    position: relative;
}
.viewPassword{
    color: black;
    position: absolute;
    top: 38px;
    right: 10px;
    font-size: 20px;
    color: gray;
    cursor: pointer;
}
